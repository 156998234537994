<template>
  <div class="box_header">
      
    <el-backtop :bottom="100">
      <div class="box_1 " @click="$router.push('/zh')">
        <span class="iconfont icon-home"></span>
      </div>
    </el-backtop>
    <div class="navs">
      <div class="logo">
        <img src="@/assets/homePage/logo.svg" alt="logo" @click="$router.push('/zh')" />
      </div>
      <div class="nav_1">
        <div v-for="i in menuList" style="overflow: hidden;" :class="{ 'btnBorder': i.id == menuId }" :key="i.id" @click="MenuBtn(i.id)">
          {{ i.title }}
        </div>
      </div>
      <div class="right">
        <lang class="lang"></lang>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "vue";
import lang from "@/component/lang.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  components: {
    lang,
  },
  setup() {
    const route = useRouter();
    const { t } = useI18n();
    const state = reactive({
      menuId: 0,
      menuList: [
        { id: 1, title: '产品中心' },
        { id: 2, title: '关于盛泰' },
        { id: 3, title: '新闻资讯' },
        { id: 4, title: '合作与发展'},
        { id: 5, title: '加入我们' },
        { id: 6, title: '联系我们' },
      ],
    });
    let methods = {
      MenuBtn(id) {
        switch (id) {
          case 1:
            route.push('/zh/product')
            break;
          case 2:
            route.push('/zh/about')
            break;
          case 3:
            route.push('/zh/news')
            break;
          case 4:
            route.push('/zh/cooperation')
            break;
          case 5:
            route.push('/zh/join')
            break;
          case 6:
            route.push('/zh/contactus')
            break;
          default:
        }
        localStorage.setItem('menuId', id)
        // console.log(localStorage.getItem('menuId'));

      },
    };
    onMounted(() => {
      // console.log(store);
      state.menuId = localStorage.getItem('menuId')
    })
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped lang="less">
.box_1 {
  height: 100%;
  width: 100%;
  padding-top: 3px;
  box-sizing: border-box;
  background-color: var(--el-bg-color-overlay);
  box-shadow: var(--el-box-shadow-lighter);
  text-align: center;
  line-height: 40px;
  color: #1989fa;
}

.box_header {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 78px;
  border: 1px solid #ccc;
  border-right: 0;
  border-left: 0;

  .navs {
    position: relative;
    line-height: 78px;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    line-height: 78px;
    margin: 0 auto;

    .logo {
      position: absolute;
      left: 0;
      width: 20%;
    }

    img {
      width: 150px;
      margin-left: 130px;
      margin-top: 20px;
      cursor: pointer;
    }

    .nav_1 {
      font-size: 16px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 40%;
      height: 100%;
      display: flex;

      div {
        flex: 1;
        text-align: center;
        line-height: 78px;
        cursor: pointer;
        user-select: none;
      }

      div:hover {
        color: #ef3e2e;
        // color: orangered;
        width: 100%;
      }

      div:active {
        color: blue
      }
    }
  }
}

.btnBorder {
  color: #ef3e2e;
}

.right {
  position: absolute;
  right: 70px;
  width: 100px;
  height: 100%;

  .lang {
    margin-top: 30px;
    cursor: pointer;
  }
}
</style>